import React, { useReducer, useEffect, useRef } from 'react'
import { lockedReducer } from '../../../utils/defaultReducer'

import { Grid, Box } from '@mui/material'
import api from '../../../api'
import { getSession } from '../../../auth'
import FinishedRow from './finishedrow'


const limit = 6

const getData = async (state, setState, between = 0, filterSettings = null, filtered=false) => {
    const enterpriseId = getSession()?.profile.enterprise.id
    let concaturl = ''

    if (between > 0)
        concaturl = `&from=${state.cursorup}&to=${between}`

    if (filterSettings)
        concaturl = concaturl.concat(filterSettings.data)

    if (filtered)
        concaturl = concaturl.concat('&filtered=true')

    const res = await api.get('api/enterprises/' + enterpriseId + `/calls/chat/finishedv2/?limit=${limit}&cursor=${state.cursor}${concaturl}`)
    if (res.status === 200) {
        const actual_ids = state.rows.map(e=>e.id)
        state.rows.push(...res.data.rows.filter(e=>!actual_ids.includes(e.id)))
        setState({
            rows: state.rows.toSorted((x, y) => y.last_msg_id - x.last_msg_id),
            cursor: res.data.cursor > 0 ? res.data.cursor : state.cursor,
            cursorup: res.data.cursorup > 0 ? res.data.cursorup : state.cursorup
        })
    }
}


const initialState = {
    rows: [],
    cursor: 0,
    cursorup: 0
}

const FinishedQueueContainer = ({ onClickItem, isManageCalls, activeChat, queueAmount, filterSettings }) => {

    const scrollArea = useRef()

    const [state, setState] = useReducer(lockedReducer, initialState)

    useEffect(() => {
        state.rows = []
        state.cursor = 0
        state.cursorup = 0
        getData(state, setState, 0, filterSettings, !isManageCalls)
    }, [filterSettings])

    useEffect(() => {
        if (queueAmount > state.cursorup && state.cursorup > 0 && queueAmount > 0)
            getData(state, setState, queueAmount, filterSettings, !isManageCalls)
    }, [queueAmount, state, filterSettings])

    return (
        <>
            {
                !isManageCalls ?
                    <Box className={'fit-tab v2 nomaxheight'} ref={scrollArea} key={`mainWindow${filterSettings}`}>
                        {
                            state.rows.map(row => (
                                <FinishedRow
                                    key={`individual-finished-row${row.id}`}
                                    parent={scrollArea}
                                    data={row}
                                    onClickItem={onClickItem}
                                    isManageCalls={isManageCalls}
                                    activeChat={activeChat}
                                />
                            ))
                        }
                        {
                            state.rows.length >= limit &&
                            <FinishedRow shouldAlwaysCheck={true} key={`individual-finished-row-last`} isVisibleHook={(v) => { v ? getData(state, setState, 0, filterSettings, !isManageCalls) : null }} parent={scrollArea} data={{ id: -100 }} />
                        }
                    </Box >
                    :
                    <Grid item lg={3} md={3} sm={3} xs={3} sx={{ minWidth: '25rem' }}>
                        <Box className="call-columns queue-chats v2" >
                            <Box className="column-header" >
                                <Box className="column-title">
                                    Finalizados
                                </Box>
                            </Box>
                            <Box className={'fit-tab v2'} ref={scrollArea} key={`mainWindow${filterSettings}`}>
                                {state.rows.map(row => (
                                    <FinishedRow
                                        key={`individual-finished-row${row.id}`}
                                        parent={scrollArea}
                                        data={row}
                                        onClickItem={onClickItem}
                                        isManageCalls={isManageCalls}
                                        activeChat={activeChat}
                                    />
                                ))}
                                {state.rows.length >= limit &&
                                    <FinishedRow shouldAlwaysCheck={true} key={`individual-finished-row-last`} isVisibleHook={(v) => { v ? getData(state, setState, 0, filterSettings) : null }} parent={scrollArea} data={{ id: -100 }} />}
                            </Box>
                        </Box>
                    </Grid >
            }
        </>

    )
}

export default FinishedQueueContainer